import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'

export const useFilters = (items) => {
  const location = useLocation()
  const [appliedFilters, setAppliedFilters] = useState([])

  const handleClickFilter = (selected) => {
    setAppliedFilters((prevState) => {
      if (!!prevState.length && prevState.includes(selected)) {
        return prevState.filter((slug) => slug !== selected)
      }
      // Currently only allowed one at a time
      return [selected]
      // return [...prevState, selected]
    })
  }

  const filteredItems = !!appliedFilters.length
    ? items.filter((item) => {
        return item.tags.some(({ slug }) => appliedFilters.indexOf(slug) >= 0)
      })
    : items

  const noResults = !!appliedFilters.length && filteredItems.length === 0

  useEffect(() => {
    if (location?.hash) {
      // Active filter
      const selectedFilter = location.hash.substring(1)
      handleClickFilter(selectedFilter)

      // Clear hash from URL
      window.history.replaceState('', '', window.location.pathname)

      // Fix issue where page is scrolled down
      window.scrollTo(0, 0)
    }
  }, [location.hash])

  return { appliedFilters, handleClickFilter, noResults, filteredItems }
}
