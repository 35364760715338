import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { useProjects } from '@/storyblok/hooks/useProjects'
import { getFilters } from '@/storyblok/helpers/getFilters'
import Projects from '@/templates/Projects'

const ProjectsStoryblok = ({ story, projects }) => {
  // Filters
  const filters = getFilters([
    {
      uid: 'filter-category',
      slug: 'category',
      items: story.content?.categories,
    },
    {
      uid: 'filter-location',
      slug: 'location',
      items: story.content?.locations,
    },
  ])

  //https://gomakethings.com/sorting-an-array-by-multiple-criteria-with-vanilla-javascript/

  const sortedProjects = projects?.sort(({ node: a }, { node: b }) => {
    // First, sort by sort by date
    if (
      a.sort_by_date ||
      a.first_published_at < b.sort_by_date ||
      b.first_published_at
    )
      return 1
    if (
      a.sort_by_date ||
      a.first_published_at > b.sort_by_date ||
      b.first_published_at
    )
      return -1

    // Then order remaining by publish date
    // if (a.first_published_at < b.first_published_at) return 1
    // if (a.first_published_at > b.first_published_at) return -1

    return 0
  })

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Projects projects={useProjects(sortedProjects)} filters={filters} />
    </SbEditable>
  )
}

ProjectsStoryblok.propTypes = {
  story: PropTypes.object,
  projects: PropTypes.arrayOf(PropTypes.object),
}

export default ProjectsStoryblok
