import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Block } from '@/components/Block'
import { Filters } from '@/components/Filters'
import { Thumbs } from '@/blocks/Thumbs'
import { useFilters } from '@/hooks/useFilters'
import * as styles from './Projects.styles.scss'

const Projects = ({ projects, filters }) => {
  const { appliedFilters, handleClickFilter, noResults, filteredItems } =
    useFilters(projects)

  return (
    <div className={styles.Projects}>
      {!!filters.length && (
        <Filters
          filters={filters}
          appliedFilters={appliedFilters}
          title="Filter Articles"
          onClickFilter={handleClickFilter}
        />
      )}
      {noResults ? (
        <Block className={styles.noResults}>
          <Container>No results for provided filters</Container>
        </Block>
      ) : (
        <Thumbs items={filteredItems} />
      )}
    </div>
  )
}

Projects.defaultProps = {
  filters: [],
  projects: [],
}

Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.object),
}

export default Projects
