import { getProject } from '@/storyblok/helpers/getProject'
import { useProjectTags } from '@/storyblok/hooks/useProjectTags'

/**
 * Process Storyblok data that can be passed to block components as props
 *
 * @param {array} data
 */

export const useProjects = (data) => {
  const tags = useProjectTags()
  const projects = []

  data?.forEach(({ node }) => {
    if (node && typeof node.content === 'string') {
      node.content = JSON.parse(node.content)
    }
    projects.push(getProject(node, tags))
  })

  return projects
}
