import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import * as styles from './Filters.styles.scss'

const Filters = ({ filters, appliedFilters, title, onClickFilter }) => {
  const handleClickFilter = (slug) => {
    if (typeof onClickFilter === 'function') onClickFilter(slug)
  }

  return (
    <Container>
      <nav className={styles.nav}>
        <div className={styles.heading}>{title}</div>
        {filters.map((column) => {
          return (
            <ul key={column.uid} className={styles.column}>
              {column.items?.map((item) => {
                return (
                  <li key={item.uid}>
                    <button
                      onClick={() => handleClickFilter(item.slug)}
                      className={classNames(styles.link, {
                        [styles.isActive]: appliedFilters.includes(item.slug),
                      })}
                    >
                      {item.title}
                    </button>
                  </li>
                )
              })}
            </ul>
          )
        })}
      </nav>
    </Container>
  )
}

Filters.defaultProps = {
  title: 'Filter items',
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.node,
  onClickFilter: PropTypes.func,
  appliedFilters: PropTypes.arrayOf(PropTypes.string),
}

export { Filters }
