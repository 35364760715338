/**
 * Take an array of Storyblok tags and return filters
 *
 * @param {array} data
 */

export const getFilters = (data) => {
  const columns = []
  data.forEach((column) => {
    columns.push({
      uid: column.uid,
      slug: column.slug,
      title: column.name,
      items: column.items?.map((item) => {
        return {
          uid: item.uuid,
          title: item.name,
          slug: item.slug,
        }
      }),
    })
  })

  return columns
}
