import { useStaticQuery, graphql } from 'gatsby'

/**
 * Pull in Storyblok tags list
 */

export const useProjectTags = () => {
  const data = useStaticQuery(
    graphql`
      query {
        tags: allStoryblokEntry(
          filter: { field_component: { eq: "project_tag" } }
        ) {
          edges {
            node {
              slug
              name
              full_slug
              uuid
            }
          }
        }
      }
    `
  )

  return data.tags?.edges.map(({ node }) => {
    return {
      uid: node.uuid,
      slug: node.slug,
      title: node.name,
    }
  })
}
